<template>
  <!-- <b-form @submit.prevent="saveButton"> -->
  <b-form>
    <!-- <b-row class="justify-content-md-center">
      <b-col md="6" cols="12">
        <h1>Campaign Settings</h1>
      </b-col>
    </b-row> -->
    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <b-form class="mt-2 mb-5">
          <b-row class="justify-content-md-center">
            <b-col cols="12">
              <h3>Campaign Settings :</h3>
            </b-col>
            <b-col cols="8">
              <b-form-group label="Button Text:" label-for="btnText">
                <b-form-input
                  v-model="button_text"
                  id="btnText"
                  placeholder="Button Text"
                />
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-form-group label="Button Link:" label-for="btnLink">
                <b-form-input
                  id="btnLink"
                  v-model="button_link"
                  type="url"
                  placeholder="https://www.example.com"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col cols="8" v-if="1 != 1">
              <b-form-group label="Button Type:" label-for="btnType">
                <v-select
                  id="btnType"
                  v-model="btnType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="addOns"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" cols="12" v-if="1 != 1">
              <b-form-group label="Button model:">
                <b-row>
                  <b-col v-for="item in addOnsModels" :key="item.id">
                    <b-img
                      class="templateModelIsHovered"
                      thumbnail
                      fluid
                      :src="path + item.image_preview"
                      alt=""
                      @click="appendItOnTemplateEditor(item.content_body)"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BModal,
  VBModal,
  BCard,
  BAvatar,
  BFormSelect,
  BTable,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  BInputGroupAppend,
  BButtonGroup,
  VBTooltip,
  BPagination,
  BButton,
  BContainer,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BContainer,
    BFormInput,
    BAvatar,
    BFormGroup,
    BFormFile,
    BFormSelect,
    BInputGroupAppend,
    BForm,
    BTable,
    BInputGroup,
    BModal,
    VBModal,
    BButtonGroup,
    VBTooltip,
    BPagination,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      path: this.$ImagesURL,
      // ########### AddOns Table ############

      button_text: null,
      button_link: null,
      addOnsModels: [],
      addOns: [],
      btnType: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "id",
          label: "Avatar",
        },
        {
          key: "url",
          label: "Image URL",
        },
        {
          key: "action",
          label: "ACTION",
        },
      ],
      hello: "hey its me",
      imagesItems: [],
      //###########################
      title: null,
      isDisabled: true,
      url: null,
      image: null,
      imageURL: null,
      data: {},
      // validation rules
      required,
      regex,
      url,
    };
  },
  computed: {
    // sortOptions() {
    //   // Create an options list from our fields
    //   return this.fields
    //     .filter((f) => f.sortable)
    //     .map((f) => ({ text: f.label, value: f.key }));
    // },
    // logitit() {
    // },
  },
  mounted() {
    // Set the initial number of items
    // this.showAllImages();
    // this.totalRows = this.imagesItems.length;
    // this.showAllButtonTypes();
  },
  created() {
    // this.showAllButtonTypes();
  },
  methods: {
    // async showAllButtonTypes() {
    //   axios.get("/get-btn-types").then(({ data }) => {
    //     // let resp = [];
    //     // resp.push(data[0].addons);
    //     data.buttonsTypes.forEach((el) => {
    //       this.addOns.push({ title: el.name, value: el.id });
    //     });
    //   });
    // },
    // deleteImage(id) {
    //   axios
    //     .post("/delete-image", {
    //       id: id,
    //     })
    //     .then(({ data }) => {
    //       if (data.status == "success") {
    //         this.showToast(
    //           "success",
    //           "TrashIcon",
    //           "Done",
    //           data.message
    //         );
    //         this.showAllAddOns();
    //       } else if (data.status == "failed") {
    //         this.showToast(
    //           "danger",
    //           "AlertTriangleIcon",
    //           "Error",
    //           "Something went wrong!"
    //         );
    //       } else if (data.status == "warning") {
    //         this.showToast(
    //           "warning",
    //           "AlertOctagonIcon",
    //           "Alert",
    //           data.message
    //         );
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    ImageInputChanged(e) {
      this.image = e.target.files[0];
    },
    async saveButton() {
      await axios
        .post("/store-button", {
          text: this.button_text,
          button_link: this.button_link,
        })
        .then(({ data }) => {
          if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            // data.button_id;
            this.$store.commit("appsManager/SET_ADDON_ID", data.button_id);
            this.button_text = null;
            this.button_link = null;
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
    // async showAllAddOns() {
    //   await axios
    //     .get("/get-images")
    //     .then((response) => {
    //       if (response.data.status == "success") {
    //         this.imagesItems = [];
    //         response.data[0].forEach((el) => {
    //           this.imagesItems.push({
    //             id: el.id,
    //             url: this.$ImagesURL + el.url,
    //           });
    //         });
    //         this.totalRows = this.imagesItems.length;
    //       } else if (response.data.status == "failed") {
    //         this.showToast(
    //           "danger",
    //           "AlertTriangleIcon",
    //           "Error",
    //           "Something went wrong!"
    //         );
    //       }
    //     })
    //     .catch((error) => {
    //       this.showToast(
    //         "danger",
    //         "AlertTriangleIcon",
    //         "Error",
    //         "Something went wrong!"
    //       );
    //     });
    // },
    // info(item, index, button) {
    //   this.infoModal.title = `Row index: ${index}`;
    //   this.infoModal.content = JSON.stringify(item, null, 2);
    //   this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    // },
    // resetInfoModal() {
    //   this.infoModal.title = "";
    //   this.infoModal.content = "";
    // },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
